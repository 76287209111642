import logo from './logo.svg';
import './App.css';
import HomePage from '../src/pages/HomePage';
// import { HomePage } from '../src/pages/HomePage'


function App() {
  return (
  <HomePage />
  );
}

export default App;
