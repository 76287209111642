import Header from "../components/Header";
import banner from "../Assets/dia-pharmacovigilance-2021-meta.jpg";
const SuccessPage = () => {
    return (
        <>
            <div>
                <div className="container">
                    <Header />
                </div>
                <div className="container-fluid p-0">
                    <div className="row w-100 m-0">
                        <img
                            src={banner}
                            className=""
                            style={{ height: "60vh" }}
                        />
                    </div></div>
                <div className="row container-fluid " style={{ backgroundColor: "rgba(247, 100, 47, 0.85)" }}>
                    <div className="d-flex text-white col-12 py-4">
                        <h3 className="mx-auto">Thank You</h3>

                    </div>
                    <div className="d-flex text-white col-12 py-4">
                        <label className="mx-auto">Our Executive will get in touch with you shortly with the information you have provided</label>
                    </div>
                </div>
            </div>
        </>
    )

}
export default SuccessPage;