import logo from '../Assets/logo-new.png'
import banner from '../Assets/dia-pharmacovigilance-2021-meta.jpg'
import medicine from '../Assets/Banner-ACG-Group-1786x642-2020.jpg'
import { FaHome } from 'react-icons/fa'
import axios from "axios";
import { useEffect, useState } from "react";
import { FaPlusCircle } from 'react-icons/fa'
import banner1 from '../Assets/pharma-wallpapers.jpg'
import banner2 from '../Assets/cave4.jpg'
import banner3 from '../Assets/cave6.jpg'
import { useNavigate } from "react-router-dom";
import Slider from '../components/Slider'
import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/scrollbar';
import Steps from '../components/Steps'
import Header from '../components/Header'
import TranslateText from '../components/GTranslate';
// import  Steps  from '../components/Steps'

const HomePage = () => {
    const navigate = useNavigate();
    const [ip, setIp] = useState('');
    const [hostCountry, setHostCountry] = useState('India')
    const slides = [
        { key: 1, url: banner1 },
        { key: 2, url: banner2 },
        { key: 3, url: banner3 },
    ];

    // const fetchIp = async () => {
    //     try {
    //         const res = await axios.get("https://api.ipify.org/?format=json");
    //         const ipAddress = res.data.ip;
    //         setIp(ipAddress);
    //         fetchCountry(ipAddress);
    //     } catch (error) {
    //         console.error('Error fetching IP:', error);
    //     }
    // };
    // const fetchCountry = async (ipAddress) => {
    //     try {
    //         const response = await axios.get(`https://ipapi.co/${ipAddress}/json`);
    //         setHostCountry(response.data.country_name);
    //         console.log(response.data);
    //     } catch (error) {
    //         console.error('Error fetching location data:', error);
    //     }
    // }
    // useEffect(() => {
    //     fetchIp();
    // }, []);

    return (<>
        <div className="container">
            <Header />
            {/* <div className="row mb-2">
                <div className="col-6">
                    <img src='https://www.hetero.com/images/press-kit-logo.jpg' width="100px" alt="" className='' />
                </div>
                <div className='col-6 text-end d-flex'>
                    <div className="d-flex my-auto ms-auto">
                        <FaHome
                            className="icon-cust ms-auto my-auto"
                            style={{ fontSize: "2em", color: "#6c757d" }}
                            onClick={() => navigate("/")}
                        />
                    </div>

                </div>
            </div> */}
        </div>
        {/* <div className='row container-fluid m-0 p-0' style={{
            backgroundImage: `url(${require("../Assets/pharma-wallpapers.jpg")})`, backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center center',
            width: '100%',
            height: '40vh'
        }}> */}
        <Swiper
            modules={[Navigation, Pagination, Autoplay, A11y]}
            spaceBetween={100}
            slidesPerView={1}
            // navigation

            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
            {slides.map((slide) => (
                <SwiperSlide key={slide.key}>
                    <div
                        style={{
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img src={slide.url} style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }} />

                    </div>
                </SwiperSlide>
            ))}
        </Swiper>


        <div className='row container-fluid m-0 p-0'>

            <Steps title="WHAT IS A SIDE EFFECT?" text="A side effect refers to any unintended medical event that occurs in a patient who has been administered a pharmaceutical product, and it may or may not be attributable to the treatment with the said product. Consequently, a side effect encompasses any unfavorable and unintended indication, symptom, or condition linked to the usage of the product." />
            <Steps title="WHY SHARE INFORMATION ABOUT SIDE EFFECTS?" text="Reporting side effects is crucial for maintaining the safety of Hetero products and ensuring the well-being of patients. Your valuable input assists us in fulfilling our reporting obligations to health authorities. These obligations mandate that we communicate information about side effects related to our products, even in instances where a direct connection between the product and the effect has not been firmly established. Your reports contribute to the ongoing monitoring and improvement of product safety." />


            <div className="col-lg-12 col-md-12 col-sm-12 " >
                <div className="my-2" style={{ minHeight: "150px", }}>
                    <div className="bg-info d-flex" style={{ height: '50px' }}>
                        <h5 className="mt-auto text-white ms-3 mb-1">HOW TO REPORT SIDE EFFECTS?</h5>

                        {/* <TranslateText text='How are You' targetLanguage='Hindi' /> */}

                    </div>
                    <div className='d-flex py-3 pb-5 row '>
                        <div className='col-12 d-flex'>
                            <label className="mx-3 my-2" >To report the side effects electronically please click the button below</label>
                            {/* <button className="btn btn-info m-2 me-5 mx-5 text-white" onClick={() => navigate("/reportevent")}>Report</button> */}
                        </div>
                        <div className='col-12 d-flex'>
                            <button className='btn btn-secondary text-white mx-auto px-5 py-2' onClick={() => navigate("/reportevent")}>Report Side Effect</button>
                            {/* <button className='mx-auto  mt-5 btn btn-secondary  btn-circle' style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "24px"
                            }}
                                onClick={() => navigate("/reportevent", { state: { data: hostCountry } })}
                            > <FaPlusCircle className="icon-cust" style={{ fontSize: '3em' }} /> </button> */}
                        </div>

                        {/* <TranslateText text='How are You' targetLanguage='Hindi' /> */}
                    </div>
                </div>
            </div>



        </div>
    </>)

}
export default HomePage;