
import { FaInfoCircle } from "react-icons/fa";
import { useEffect } from "react";

const IButton = ({ title }) => {
    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltips = tooltipTriggerList.map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

        return () => {
            tooltips.forEach(tooltip => tooltip.dispose());
        };
    }, []);

    return (
        <button
            type="button"
            className="btn mx-1 px-0"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title={title}

        >
            <FaInfoCircle className="my-2" />
        </button>
    );
}

export default IButton;

