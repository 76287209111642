import axios from "axios";
// import { GlobalSharedReduxFn } from "Context";
import moment from "moment";
function handleGenericErrorFn(error) {
    let errorMessage = error;
    const resp = error.response;
    console.log("generic error", error, resp);
    if (resp) {
        errorMessage = resp?.data?.ErrorInfo || error?.message || "Not connected with api";
        if (resp.status === 401) {
            //ToasterFn.Error(errorMessage, "Authentication Error Access");
            alert("Logout")
        } else if (resp.status === 403) {
            // ToasterFn.Error(errorMessage, "Unathorized Access");
            alert("unautorize")
        }
        else if (resp.status === 400) {
            return errorMessage;

        }
        else if (resp.status === 404) {
            //ToasterFn.Error("Requested resource not found.", "Not Available");
        }
        else if (resp.status === 500) {
            //alert(errorMessage)
            return errorMessage;
            //return resp.data.ErrorInfo;
            //ToasterFn.Error((errorMessage.length > 100 ? "Server encounter an error." : errorMessage), "Server Error");
        } else {
            return error;
        }
    } else {
        return error;
    }

}

function prepareFormData(formData, data, parentKey = '') {

    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        if (typeof data === 'object' && moment.isMoment(data.startDate)) {
            formData.append(parentKey, data.startDate?.toISOString());
        } else {
            Object.keys(data).forEach(key => {
                prepareFormData(formData, data[key], parentKey ? `${parentKey}${parseInt(key)
                    >= 0 ? `[${key}]` : `.${key}`}` : key);
            });

        }
    } else {

        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}


export const FnHttpService = {
    promiseHandlerSingle(promiseFn, oDispatch, fnSuccess, fnError) {
        promiseFn.then((data) => {
            if (typeof fnSuccess == "function")
                fnSuccess(data.data || data);

        }, (err) => {
            let errors = handleGenericErrorFn(err);
            if (typeof fnError == "function") {
                fnError(errors);
            }

        }).finally((fnl) => {
            // oDispatch(GlobalSharedReduxFn.pageLoadingStateSet(false))
        })

    },
    promiseHandler(promiseArray, oDispatch, fnSuccess, fnError) {
        axios.all(promiseArray).then((data) => {
            //{ config, status, statusText, headers, data }
            if (typeof fnSuccess == "function")
                fnSuccess(data.data || data);

        }, (err) => {
            let errors = handleGenericErrorFn(err);
            if (typeof fnError == "function") {
                fnError(errors);
            }

        }).finally((fnl) => {
        })

    },
    toFormData(formData, data, parentKey = '') {
        return prepareFormData(formData, data, parentKey);
    }
}

