import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import FAQ from "../Assets/languages/FAQ.pdf";
import glossary from "../Assets/languages/GLOSSARY.pdf";
import policy from "../Assets/languages/Policy.pdf";
import guide from "../Assets/languages/How to fill AE form.pdf";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <Navbar collapseOnSelect expand="lg" variant="light">
      <Navbar.Brand as={Link} className="" to="/">
        <Nav.Link as={Link} to="/" onClick={() => navigate("/")}>
          <FaHome className="icon-cust" style={{ fontSize: "2em", color: "#6c757d" }} />
        </Nav.Link>

      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto me-3">

          <Nav.Link onClick={() => window.open(guide)}>
            How to fill AE form
          </Nav.Link>
          <Nav.Link onClick={() => window.open(glossary)}>
            Glossary
          </Nav.Link>
          <Nav.Link onClick={() => window.open(policy)}>
            Privacy Policy
          </Nav.Link>
          <Nav.Link onClick={() => window.open(FAQ)}>
            FAQ
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  );
};

export default Header;
// import logo from "../Assets/logo-new.png";
// import banner from "../Assets/dia-pharmacovigilance-2021-meta.jpg";
// import { FaHome } from "react-icons/fa";
// import FAQ from "../Assets/languages/FAQ.pdf";
// import glossary from "../Assets/languages/GLOSSARY.pdf";
// import policy from "../Assets/languages/Policy.pdf";
// import guide from "../Assets/languages/How to fill AE form.pdf";
// import { Link, useNavigate } from "react-router-dom";
// const Header = () => {
//   const navigate = useNavigate();
//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-6 py-5">
//           {/* <img src='https://www.hetero.com/images/press-kit-logo.jpg' width="100px" alt="" className="" onClick={() => navigate("/")} /> */}
//         </div>
//         <div className="col-6 ">
//           <div className="d-flex mt-4 ms-auto text-secondary">
//             <Link className="nav-link mx-2 my-auto ms-auto" onClick={() => window.open(guide)}>
//               How to fill AE form
//             </Link>
//             <Link className="nav-link mx-2 my-auto " onClick={() => window.open(glossary)}>
//               Glossary
//             </Link>

//             <Link className="nav-link mx-2 my-auto " onClick={() => window.open(policy)}>
//               Privacy Policy
//             </Link>
//             <Link className="nav-link mx-2 my-auto " onClick={() => window.open(FAQ)}>
//               FAQ
//             </Link>
//             <FaHome
//               className="icon-cust mx-2 my-auto "
//               style={{ fontSize: "2em", color: "#6c757d" }}
//               onClick={() => navigate("/")}
//             />
//           </div></div>
//       </div>
//     </div>
//   )
// }
// export default Header;