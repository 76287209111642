
import { useNavigate } from "react-router-dom";
import { BsPlayCircleFill } from 'react-icons/bs'
const Steps = ({ title, text, isButton }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-12 border" style={{ minHeight: '200px' }}>
                <div className="my-2 d-flex flex-column">
                    <div className="bg-info d-flex" style={{ height: '50px' }}>
                        <h5 className="mt-auto text-white ms-3 mb-1">{title}</h5>
                    </div>
                    <div className='d-flex flex-column py-3 pb-5 me-5'>
                        <p className="mx-3 my-2 flex-grow-1" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{text}</p>

                    </div>

                    {/* <div className='col-12 d-flex'>
                        <button className='mx-auto  mt-5 btn btn-info  btn-circle' style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "24px"
                        }}
                            onClick={() => navigate("/reportevent")}
                        > <BsPlayCircleFill className="icon-cust" style={{ fontSize: '3em' }} /> </button>
                    </div> */}
                </div>
            </div>

        </>
    )
}
export default Steps;